import React, { useCallback, useLayoutEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageCropper from "../../components/Cropper";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Textarea from "../../components/UI/Textarea";
import { getImageURL } from "../../helpers/image";
import { editBanner, getBanner } from "../../services/banner";

const BannerEdit = () => {
  const navigate = useNavigate();
  const { bannerId } = useParams();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const [loading, setLoading] = useState(true);
  const [editImageBanner, setEditImageBanner] = useState({
    show: false,
    data: [],
  });

  useLayoutEffect(() => {
    getBanner(bannerId)
      .then((res) => {
        if (res.media) {
          res.media = getImageURL({
            path: res.media,
            type: "banner",
            size: "full",
          });
        }
        reset(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    editBanner(data)
      .then(() => {
        NotificationManager.success("Акция успешо обновлена");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Редактировать рекламный баннер" />
      <section className="box">
        <div>
          <Link
            to="/banners"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>Редактировать рекламный баннер</h3>
          <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить
          </Button>
        </div>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Input
                label="Заголовок"
                name="title"
                errors={errors}
                register={register}
                validation={{
                  required: "Обязательное поле",
                }}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <Textarea
                rows={6}
                label="Текст"
                name="desc"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Input
                label="Ссылка"
                name="link"
                placeholder="Введите ссылку"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Input
                defaultValue={0}
                label="Порядок"
                name="priority"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={12}>
            <DragDropFile
              file={data.media}
              onChange={(e) =>
                setEditImageBanner((info) => ({
                  show: !info.show,
                  data: e,
                }))
              }
            />
            <ImageCropper
              file={editImageBanner.data[0]}
              show={editImageBanner.show}
              aspect={54 / 14}
              setShow={(e) =>
                setEditImageBanner((info) => ({ ...info, show: e }))
              }
              onComplete={(e) => {
                e && setValue("file", e.file);
                e && setValue("media", e.blob);
              }}
            />
          </Col>
          <Col md={12}>
            <p>Соотношение сторон 54 x 14(Например: 1600px x 415px)</p>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default BannerEdit;
