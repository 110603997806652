import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ImageCropper from "../../components/Cropper";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { createBanner } from "../../services/banner";

const AdCreate = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      style: "catalog",
      type: 1,
    },
  });

  const data = useWatch({ control });
  const [editImageBanner, setEditImageBanner] = useState({
    show: false,
    data: [],
  });

  useEffect(() => {
    searchParams.get("type") &&
      reset({ type: Number(searchParams.get("type")) });
  }, []);

  const onSubmit = useCallback((data) => {
    if (!data?.file) {
      return NotificationManager.error("Добавьте изображение");
    }
    createBanner(data)
      .then(() => {
        NotificationManager.success("Баннер успешно создана");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  return (
    <Container fluid={true}>
      <Meta title="Создать баннер" />
      <section className="box">
        <div>
          <Link
            to="/ads"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>Создать баннер</h3>
          <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить
          </Button>
        </div>

        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Input
                label="Заголовок"
                name="title"
                errors={errors}
                register={register}
                validation={{
                  required: "Обязательное поле",
                }}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <Textarea
                rows={6}
                label="Текст"
                name="desc"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Input
                label="Ссылка"
                name="link"
                placeholder="Введите ссылку"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Input
                defaultValue={0}
                label="Порядок"
                name="priority"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <Select
                label="Тип баннера"
                data={[
                  { title: "В каталог (Прямоугольные)", value: "catalog" },
                  { title: "В объявление (Квадратные)", value: "announcement" },
                ]}
                value={data.style}
                onClick={(e) => setValue("style", e.value)}
              />
            </div>
          </Col>
          <Col md={12}>
            <DragDropFile
              file={data.media}
              onChange={(e) =>
                setEditImageBanner((info) => ({
                  show: !info.show,
                  data: e,
                }))
              }
            />
            <ImageCropper
              file={editImageBanner.data[0]}
              show={editImageBanner.show}
              aspect={!data?.style || data.style === "catalog" ? 4.2 / 1 : 1}
              setShow={(e) =>
                setEditImageBanner((info) => ({ ...info, show: e }))
              }
              onComplete={(e) => {
                e && setValue("file", e.file);
                e && setValue("media", e.blob);
              }}
            />
          </Col>
          {(!data?.style || data.style === "catalog") ?
            <Col md={12}>
              <p>Соотношение сторон 42 x 10(Например: 1596px x 380px)</p>
            </Col>
            :
            <Col md={12}>
              <p>Соотношение сторон 1 x 1(Например: 300px x 300px)</p>
            </Col>
          }
        </Row>
      </section>
    </Container>
  );
};

export default AdCreate;
